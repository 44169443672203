import React from "react";
import { Link, graphql } from "gatsby";

export const fragment = graphql`
    fragment Button on SanityButton {
        _key
        _type
        buttonLink
        buttonTitle
    }
`;

const StyledLink = (props) => {
    if (props.link.length) {
        var link = props.link;
        var anchor = props.linkText;
    } else {
        var link = props.link.buttonLink;
        var anchor = props.link.buttonTitle;
    }

    const internal = /^\/(?!\/)/.test(link);
    if (internal) {
        return (
            <Link className="button" to={link}>
                {anchor}
            </Link>
        );
    } else {
        return (
            <a className="button" href={link}>
                {anchor}
            </a>
        );
    }
};

export default StyledLink;
