import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../layout/layout";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import StyledLink from "../components/styledlink";
import FormattedText from "../components/portableText";

export const query = graphql`
    query CompetitionQuery {
        sanityGlobal(_id: { eq: "f4d0bdaa-f5e1-4bee-b8a5-4df771781c3e" }) {
            _rawCompetitionIntro
        }
        allSanityCompetition {
            edges {
                node {
                    id
                    competition_name
                    competition_url {
                        current
                    }
                    competition_image {
                        asset {
                            gatsbyImageData
                        }
                    }
                    entry_date
                    formlink
                    short_desc
                    age_group
                }
            }
        }
    }
`;

const Competitions = ({ data }) => {
    useEffect(() => {
        if (data.allSanityCompetition) {
            document.body.classList.add("competitions");
        }
    });
    const competitions = data.allSanityCompetition.edges;
    let urlPath = "/competition/";
    return (
        <Layout>
            <div>
                <StaticImage src="../images/hero-image.png" alt="Climate image" />
                <div className="container relative mx-auto text-center">
                    <div className="w-full sm:w-2/3 mx-auto pb-16">
                        <h1>Competitions</h1>
                    </div>
                </div>
            </div>
            <main id="main" className="competitions p-6">
                {data.sanityGlobal._rawCompetitionIntro ? (
                    <div className="container mx-auto">
                        <div className="w-full sm:w-2/3 lg:w-1/2 text-center mx-auto mt-6 mb-24">
                            <FormattedText blocks={data.sanityGlobal._rawCompetitionIntro} />
                        </div>
                    </div>
                ) : null}

                {competitions.map((competition, i) => (
                    <div className="container w-full sm:w-11/12 md:w-5/6 mx-auto flex flex-wrap mb-12 items-center">
                        <div className="w-full sm:w-1/3 order">
                            <div class="competition-details">
                                <h2>{competition.node.competition_name}</h2>
                                <div className="flex flex-wrap competition-info">
                                    <span>Entry date: {competition.node.entry_date}</span>
                                    <span>Ages: {competition.node.age_group}</span>
                                </div>
                                <p>{competition.node.short_desc}</p>
                            </div>
                            {competition.node.competition_url ? (
                                <StyledLink
                                    link={urlPath + competition.node.competition_url.current}
                                    linkText="Enter"
                                />
                            ) : null}
                        </div>
                        <div className="w-full sm:w-2/3">
                            <GatsbyImage
                                image={competition.node.competition_image.asset.gatsbyImageData}
                            />
                        </div>
                    </div>
                ))}
            </main>
        </Layout>
    );
};

export default Competitions;
